<template>
  <div class="transaction-panel">
    <div class="layout-left">
      <div class="detail-info mb-auto">
        <InputText
          v-model="filter.search"
          type="text"
          faIcon="search"
          className="mb-2 rounded bg-second"
          @onClickIcon="handleSearchCustomer"
          @onEnter="handleSearchCustomer"
        />

        <p class="data-info d-flex justify-content-between">
          ข้อมูลลูกค้า
          <u @click="clearSearch" class="text-desc-message text-black pointer"
            >ล้างค่า</u
          >
        </p>
        <p class="title-info">หมายเลขสมาชิก (Member No.)</p>
        <p class="data-info">{{ user.member_id || "-" }}</p>
        <p class="title-info">ชื่อลูกค้า (Customer Name)</p>
        <p class="data-info">
          {{ user.first_name_th || "-" }} {{ user.last_name_th }}
        </p>
        <p class="title-info">โทรศัพท์ (Telephone)</p>
        <p class="data-info">{{ user.telephone || "-" }}</p>
        <div v-if="$store.state.isCenterPoint == 0">
          <p class="title-info">คะแนนสะสมสาขา</p>
          <p class="data-info" v-if="user.user_guid">
            {{ user.point | numeral("0,0") }} คะแนน
          </p>
          <p v-else>-</p>
        </div>
        <p class="title-info">คะแนนสะสม</p>
        <p class="data-info" v-if="user.user_guid">
          {{ user.center_point | numeral("0,0") }} คะแนน
        </p>
        <p v-else>-</p>
        <div class="text-center">
          <b-button
            variant="primary-color"
            class="w-50"
            :disabled="!user.user_guid"
            @click="handleRedeemProduct"
          >
            แลกคะแนน
          </b-button>
        </div>
      </div>
    </div>
    <div class="layout-right p-3">
      <HeaderPanel
        title="รายการแลกของ"
        :filter="filter"
        @search="serachFilter"
        @clear="clearSearch"
      >
        <template v-slot:filter-title>
          <b-form-checkbox
            v-model="filter.currentOrAll"
            :value="1"
            :unchecked-value="0"
            @change="handleChecked"
          >
            แสดงเฉพาะสาขา {{ branchName }}
          </b-form-checkbox>
        </template>
        <template v-slot:filter-option>
          <b-col>
            <InputText
              v-model="filter.memberId"
              textFloat="หมายเลขสมาชิก (Member No.)"
              placeholder="หมายเลขสมาชิก (Member No.)"
              type="text"
              name="memberId"
              className="mb-3"
              @onEnter="serachFilter"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.memberName"
              textFloat="ชื่อสมาชิก (Member Name)"
              placeholder="ชื่อสมาชิก (Member Name)"
              type="text"
              name="memberName"
              className="mb-3"
              @onEnter="serachFilter"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.telephone"
              textFloat="เบอร์โทรศัพท์ (Telephone)"
              placeholder="เบอร์โทรศัพท์ (Telephone)"
              type="text"
              name="telephone"
              className="mb-3"
              @onEnter="serachFilter"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.productName"
              textFloat="ชื่อสินค้า (Product Name)"
              placeholder="ชื่อสินค้า (Product Name)"
              type="text"
              name="productName"
              className="mb-3"
              @onEnter="serachFilter"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="รอบแลกคะแนนสะสม"
              :options="redeemOption"
              v-model="filter.redeemId"
              @onDataChange="(val) => (filter.redeemId = val)"
              textField="name"
              valueField="id"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="สถานะการซื้อ (Order Status)"
              :options="statusList"
              v-model="filter.redeemStatusId"
              @onDataChange="(val) => (filter.redeemStatusId = val)"
              textField="name"
              valueField="id"
            />
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(imge)="data">
                <img
                  v-if="data.item.imge"
                  :src="data.item.imge"
                  alt="product"
                  width="50"
                  class="mr-2"
                />
                <img
                  v-else
                  src="@/assets/images/noimg.png"
                  alt="no-image-product"
                  width="50"
                  class="mr-2"
                />
              </template>
              <template v-slot:cell(used_point)="data">
                <div>{{ data.item.used_point | numeral("0,0") }} คะแนน</div>
              </template>
              <template v-slot:cell(redeem_date)="data">
                <p>
                  {{
                    $moment(data.item.redeem_date)
                      .add(543, "years")
                      .format($formatDateNewFull)
                  }}
                </p>
              </template>
              <template v-slot:cell(status_name)="data">
                <p
                  :class="
                    data.item.status >= 6
                      ? 'text-danger'
                      : data.item.status == 5
                      ? 'text-success'
                      : 'text-warning'
                  "
                >
                  {{ data.item.status_name }}
                </p>
              </template>
              <template v-slot:cell(action)="data">
                <div
                  class="d-flex align-items-center justify-content-end gap-1"
                >
                  <b-button
                    variant="link"
                    @click="confirmRedeem(data.item)"
                    v-if="data.item.status === 3"
                  >
                    <img
                      src="@/assets/images/icons/ic_send_24px.png"
                      width="20"
                      height="20"
                    />
                  </b-button>
                  <b-button
                    variant="link"
                    block
                    v-if="data.item.cancel_valid === 1"
                    @click="confirmCancel(data.item)"
                  >
                    <img
                      src="@/assets/images/icons/ic_block_24px.png"
                      width="20"
                      height="20"
                    />
                  </b-button>
                  <img
                    src="@/assets/images/icons/note.png"
                    alt="transfer"
                    class="action-img pointer"
                    width="20"
                    height="20"
                    center
                    @click="$refs.modalNote.show(data.item)"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <ModalCustomerList
      ref="modalCustomerList"
      @setUserSelect="setUserSelect"
      :redeemId="redeemId"
    />
    <SlideCustomerList
      ref="SlideCustomerList"
      :redeemId="redeemId"
      @setUserSelect="setUserSelect"
    />
    <ModalOTP ref="modalOTP" @success="pagination(1)" />
    <ModalNote ref="modalNote" :branchId="branchId" @success="pagination(1)" />
    <ModalConfirmCancel ref="modalConfirmCancel" @success="pagination(1)" />
    <ModalProduct
      :point="redeemPoint"
      :data="productList"
      :customer="user"
      ref="modalProduct"
      @success="redeemSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCustomerList from "./components/ModalCustomerList";
import InputText from "@/components/inputs/InputText";
import HeaderPanel from "@/components/HeaderPanel";
import InputSelect from "@/components/inputs/InputSelect";
import Pagination from "@/components/Pagination";
import ModalOTP from "./components/ModalOTP";
import ModalNote from "@/components/report/pointBalance/ModalNote";
import ModalProduct from "./components/ModalProduct";
import ModalConfirmCancel from "./components/ModalConfirmCancel";
import SlideCustomerList from "@/views/pages/home/mobile/components/SlideCustomerList";

export default {
  components: {
    ModalCustomerList,
    InputText,
    HeaderPanel,
    InputSelect,
    Pagination,
    ModalOTP,
    ModalNote,
    ModalProduct,
    ModalConfirmCancel,
    SlideCustomerList,
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      branchId: "getBranchId",
      pointSystem: "getPointSystem",
      branchName: "getBranchName",
      isMainLoading: "getMainLoading",
    }),
  },
  data() {
    return {
      user: {
        center_point: 0,
        created_time: "",
        credit: 0,
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        member_level_id: 0,
        member_level_name: "",
        point: 0,
        telephone: "",
        user_guid: "",
        user_id: 0,
        valid_user: 1,
        birthday: "",
        consent_status: "",
        sumPoint: 0,
      },
      params: {
        userGuid: "",
        transactionId: "",
        showOther: 0,
      },
      form: {
        transactionId: 0,
      },
      transactionList: [],
      transactions: [],
      filter: {
        currentOrAll: 1,
        page: 1,
        take: 5,
        branchId: 0,
        search: "",
        memberId: "",
        memberName: "",
        telephone: "",
        productName: "",
        redeemId: 0,
        redeemStatusId: 0,
      },
      totalPage: 1,
      customerList: [],
      statusList: [],
      redeemOption: [],
      redeemNameList: [],
      productList: [],
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "imge",
          label: "",
        },
        {
          key: "gift",
          label: "Gift",
          tdClass: "text-wrap-customs min-w-250",
        },
        {
          key: "member_id",
          label: "Member ID",
        },
        {
          key: "name",
          label: "Name-Surname",
          tdClass: "min-w-200",
        },
        {
          key: "telephone",
          label: "Telephone",
        },
        {
          key: "used_point",
          label: "Used Point",
          tdClass: "min-w-100",
        },
        {
          key: "branch_name",
          label: "Branch",
          tdClass: "min-w-200",
        },
        {
          key: "redeem_date",
          label: "Redeemed Date",
          tdClass: "min-w-150",
        },
        {
          key: "status_name",
          label: "status",
          tdClass: "min-w-150",
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      isLoading: true,
      redeemId: 0,
      isMobile: false,
      redeemPoint: 0,
    };
  },
  async created() {
    this.isLoading = true;
    this.filter.branchId = this.branchId;
    this.params.userGuid = this.$route.params.userGuid || "";
    await this.getRedeemName();
    await this.getStatusList();
    await this.getRedeemList();

    if (this.redeemNameList.length > 0) {
      // this.filter.redeemId = this.redeemNameList[0].id;
      // this.redeemId = this.redeemNameList[0].id;
    }
    this.isLoading = false;
    if (this.$route.query.telephone) {
      this.filter.search = this.$route.query.telephone || "";
      await this.handleSearchCustomerFirst();
    }

    await this.getList();
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768;
      this.$refs.modalCustomerList.hide();
      this.$refs.SlideCustomerList.hide();
    },
    getList: async function () {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.branch_id = this.filter.branchId;
      payload.customer_name = this.filter.memberName;
      payload.telephone = this.filter.telephone || this.user.telephone;
      payload.redeem_id = this.filter.redeemId;
      payload.product_name = this.filter.productName;
      payload.redeem_status = this.filter.redeemStatusId;
      payload.member_id = this.filter.memberId;
      payload.current_or_all = this.filter.currentOrAll;
      await this.axios
        .post(`${this.$baseUrl}/redeem/get_redeem_list`, payload)
        .then((data) => {
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
          }
        });

      this.isBusy = false;
    },
    async getRedeemName() {
      await this.axios
        .get(`${this.$baseUrl}/redeem/get_redeem_name`)
        .then((data) => {
          if (data.result == 1) {
            this.redeemNameList = data.detail;
          }
        });
    },
    async getRedeemList() {
      let list = [{ id: 0, name: "ทั้งหมด" }];
      await this.axios
        .get(`${this.$baseUrl}/redeem/get_redeem_id_list`)
        .then((data) => {
          if (data.result == 1) {
            this.redeemOption = list.concat(data.detail);
          }
        });
    },
    getStatusList: async function () {
      let list = [{ id: 0, name: "ทั้งหมด" }];

      await this.axios
        .get(`${this.$baseUrl}/redeem/get_redeem_order_status_list`)
        .then((data) => {
          if (data.result == 1) {
            this.statusList = list.concat(data.detail);
          }
        });
    },
    async getUserDetail(userGuid) {
      await this.axios
        .get(
          `${this.$baseUrl}/customer/user_lookup_byid/${userGuid}/${this.branchId}`
        )
        .then(async (data) => {
          if (data.result == 1) {
            this.user = data.detail;
            this.user.sumPoint = data.detail.center_point + data.detail.point;
            this.filter.search = "";
            this.filter.memberId = this.user.member_id;
            this.pagination(1);
          }
        });
      await this.getTheme();
    },
    init() {
      this.user = {
        center_point: 0,
        created_time: "",
        credit: 0,
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        member_level_id: 0,
        member_level_name: "",
        point: 0,
        telephone: "",
        user_guid: "",
        user_id: 0,
        valid_user: 1,
        birthday: "",
        consent_status: "",
        sumPoint: 0,
      };
      this.filter = {
        currentOrAll: 1,
        page: 1,
        take: 5,
        branchId: this.filter.branchId,
        search: "",
        memberId: "",
        memberName: "",
        telephone: "",
        productName: "",
        redeemId: 0,
        redeemStatusId: 0,
      };
      this.items = [];
      // if (this.redeemNameList.length > 0) {
      //   this.filter.redeemId = this.redeemNameList[0].id;
      // }
    },
    clearSearch() {
      this.init();
      this.getList();
    },
    handleSearchCustomer() {
      if (this.isMobile) this.$refs.SlideCustomerList.show(this.filter.search);
      else this.$refs.modalCustomerList.show(this.filter.search);
    },
    handleSearchCustomerFirst() {
      this.$refs.modalCustomerList.autoSelect(this.filter.search);
    },
    async setUserSelect(user) {
      await this.getUserDetail(user.user_guid);
    },
    handleChecked(val) {
      if (val) this.filter.branchId = this.branchId;
      else this.filter.branchId = 0;

      this.pagination(1);
    },
    serachFilter() {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    async confirmRedeem(item) {
      this.$refs.modalOTP.show(item);
    },
    async confirmCancel(item) {
      this.$refs.modalConfirmCancel.show(item, this.user);
    },
    handleRedeemProduct: async function () {
      await this.$store.dispatch("setMainLoading", true);
      let body = {
        user_guid: this.user.user_guid,
        branch_id: this.branchId,
      };
      await this.axios
        .post(`${this.$baseUrl}/redeem/get_redeem_item_list`, body)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.productList = data.detail;
          } else {
            this.warningAlert(data.message);
          }
        });
      this.$refs.modalProduct.show();
    },
    redeemSuccess() {
      this.setUserSelect(this.user);
    },
    async getTheme() {
      const theme = await this.axios(`${this.$baseUrl}/customer/theme`);
      if (theme.is_center_point == 1) {
        this.redeemPoint = this.user.center_point;
      } else {
        this.redeemPoint = this.user.point + this.user.center_point;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .layout-left {
  width: 300px;
  background: #f7f7f7;
  height: calc(100vh - 46px);
  position: relative;
  font-family: Kanit-Medium !important;
  .bg-second .input-custom .has-icon input {
    background: var(--secondary-color) !important;
  }
  .detail-info {
    padding: 20px;
    .title-info {
      font-size: 14px;
      color: grey;
    }
    .data-info {
      color: #333;
      font-size: 16px;
      text-align: left;
      margin-bottom: 15px;
    }
  }
}
::v-deep .layout-right {
  width: calc(100% - 300px);
  height: calc(100vh - 46px);
  overflow: auto;
  .text-ref-no {
    font-family: Kanit-Medium !important;
    font-size: 20px;
  }
  .text-time,
  .text-saleId {
    font-family: Kanit-Medium !important;
    font-size: 14px;
  }
  .text-status {
    font-family: Kanit-Medium !important;
    font-size: 18px;
  }
  .text-status.text-success {
    color: #3c763d !important;
  }

  .head-total {
    p {
      text-align: center;
      color: var(--primary-color) !important;
      font-size: 14px;
      font-weight: 600;
    }
    .col:first-of-type {
      padding-left: 0;
    }
    .col {
      padding: 0 5px;
    }
    .col:last-of-type {
      padding-right: 0;
    }
    .bg-secondary {
      padding: 15px 0;
      background: var(--secondary-color) !important;
    }
  }
  .product-list-container {
    overflow: auto;
    height: calc(100% - 46px - 89px - 87px - 40px);
    position: relative;
    .wrap-no-product {
      width: 100%;
      height: 100%;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      .no-product {
        text-align: center;
        font-size: 20px;
        padding: 15px;
        background-color: #f7f7f7;
        border-radius: 10px;
        color: #dcdcdc;
        width: 450px;
      }
    }
  }
  .header-products {
    font-size: 14px;
    font-weight: bold;
  }
  .product-panel {
    background-color: rgb(244, 244, 244) !important;
  }
  .product-panel.active {
    background-color: var(--secondary-color) !important;
  }
  .item-products {
    color: #707070;
    font-size: 16px;
    border-radius: 8px;
    font-family: Kanit-Medium !important;
    svg {
      color: #000;
    }
    .price {
      font-size: 14px;
      text-align: center;
    }
    .product-info {
      font-size: 12px !important;
    }
    span {
      font-size: 12px !important;
      color: #707070 !important;
    }
    .normal-unit-price {
      text-decoration: line-through;
    }
  }
}
::v-deep .btn-link {
  border-color: transparent !important;
  background-color: transparent !important;
  text-decoration: underline !important;
  padding: 5px 0;
  color: var(--primary-color) !important;
}
.text-wrap-customs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 270px !important;
}

::v-deep .input-custom {
  label.title {
    white-space: break-spaces;
    word-break: break-word;
  }
}

::v-deep .table-main {
  table {
    min-width: max-content;
  }
}

.transaction-panel {
  display: flex;
}

.action-desktop {
  display: block;
}

.action-mobile {
  display: none;
}

::v-deep .header-title {
  gap: 8px;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  flex: 1;
}

@media (max-width: 768px) {
  .transaction-panel {
    display: block;

    .layout-left {
      width: 100%;
      height: 100%;

      .detail-info {
        width: 100%;
      }
    }

    .layout-right {
      width: 100%;
      height: 100%;

      .transaction-status {
        display: block;
        padding: 12px 0;

        .detail {
          text-align: left;
        }
      }
    }

    .action-desktop {
      display: none;
    }

    .action-mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      margin-bottom: 16px;
      width: 100%;
    }
  }
}
</style>
